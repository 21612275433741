
import tool from '@/lib/helpers'
import InputTelephone from '@/components/input_telephone'

export default {
  components: { InputTelephone }

  data: -> {
    tab: 1,
    user: {
      login: "",
      first_name: "",
      last_name: "",
      profile: {
        sms_marketing: false
        terms: false
      }
    }
    rules: {
      required: (value) => !!value || @$t('user_form_validate_required')
    }
  }

  methods: {
    next_tab: ->
      current = @tabs.indexOf(@tab)
      if @tab == 1
        @tab = @tab + 1 if @$refs.user_fields.validate()
      else
        @tab = @tab + 1

    previous_tab: ->
      @tab = @tab - 1 || 0

    go_to_tab: (id) ->
      @tab = id

    submit: ->
      if @$refs.user_fields.validate() && @$refs.user_name.validate()
        @$store.dispatch("account/update", @user)
        .then (response) =>
          if not !!@$store.state.account.errors
            @$store.dispatch('layout/notify', @$t('popup_account_update_ok'))
            @$router.push('/')
        .catch (error) ->
          console.error 'profile_page:submit.error', error
  }

  computed: {
    account: ->
      @$store.state.account.current

    agency_application: ->
      @$store.getters['application/is_agency']

    api_validation: ->
      @$store.state.account.errors || {}

    loading: ->
      @$store.state.account.loading

    tabs: ->
      [1, 2]
  }

  watch: {
    account: (account) ->
      @$router.push("/") if !account?.id

    api_validation: (error_object) ->
      @go_to_tab(1) if error_object
  }

  created: ->
    @user = @$store.getters['account/form_data']
    @$store.commit('layout/page_nav', { title: @$t("profile_page_header") })

  destroyed: ->
    @$store.commit('account/flush_errors')
}
